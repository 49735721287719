const navigation = {
  main: [
    { name: 'Om oss', href: '/om_oss' },
    { name: 'BM-bloggen', href: '/information/blog' },
    { name: 'Dokument & information', href: '/information/dokumentation' },
    { name: 'Privat', href: '/privat' },
    { name: 'BRF', href: '/brf' },
    { name: 'Entreprenad', href: '/entreprenad' },
  ],
  social: [
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/besiktningsman/',
      icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" /><rect width="4" height="12" x="2" y="9" /><circle cx="4" cy="4" r="2" /></svg>
      ),
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Besiktningsman',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/besiktningsman.se?igshid=YmMyMTA2M2Y=',

      icon: (props) => (
        <svg fill="inherit" viewBox="0 0 24 24"  {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Pinterest',
      href: 'https://se.pinterest.com/besiktningsman',
      icon: (props) => (
        <svg fill="inherit" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"  {...props}><g strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M16.292 5C10.536 5 7.48 8.866 7.48 13.077c0 1.955 1.039 4.392 2.707 5.168.253.118.387.064.443-.18.047-.185.273-1.089.373-1.511a.402.402 0 0 0-.095-.386c-.55-.667-.988-1.896-.988-3.041 0-2.942 2.228-5.787 6.021-5.787 3.28 0 5.571 2.23 5.571 5.426 0 3.61-1.82 6.108-4.191 6.108-1.309 0-2.291-1.081-1.978-2.413.378-1.584 1.106-3.298 1.106-4.438 0-1.026-.552-1.883-1.685-1.883-1.341 0-2.415 1.385-2.415 3.241 0 1.182.4 1.978.4 1.978s-1.323 5.593-1.563 6.634c-.413 1.765.051 4.625.094 4.876.021.134.172.18.252.066.129-.175 1.725-2.565 2.174-4.292l.828-3.171c.439.829 1.707 1.531 3.061 1.531 4.021 0 6.923-3.703 6.923-8.299C24.52 8.301 20.92 5 16.292 5z"></path></g></svg>

      ),
    },
  ],
}

export default function Footer() {
  return (
    <footer className="">
      <div className="mx-auto  px-6 pt-20 sm:pt-24 lg:px-8 space-y-4">
        <div className="mx-auto space-y-3 pb-6 text-center">
          <img src="/logos/bm-logo-solo.svg" className="mx-auto h-12 w-auto text-center" alt="✓" />

          <div className="bm-logo-lowercap text-xl text-stone-900 md:text-3xl">
            besiktningsman<span className="text-blue-500  font-black">.</span>se
            <br />
          </div>
          <div className="text-sm font-medium text-stone-500">
            Mer än bara ett besiktningsföretag
          </div>
        </div>
        <nav aria-label="Footer" className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm font-medium leading-6 text-stone-600 hover:text-stone-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className=" flex justify-center space-x-10 pb-6">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-stone-400 hover:text-stone-500 ring-1 ring-stone-400 p-1.5 rounded-lg shadow-sm bg-white fill-stone-400">
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" className="h-6 w-6" />
            </a>
          ))}
        </div>
        <div className="text-sm/6 font-medium text-center mx-auto sm:flex sm:items-center space-x-3 justify-center py-6 border-t border-stone-300 w-full" >
          <p className="">

            &copy; {new Date().getFullYear()} Besiktningsman.se Sverige AB

          </p>

          <a href="https://goo.gl/maps/SkuXoAyaMgfc174L7" className="underline text-stone-600 hover:text-stone-900 font-semibold"> kontor: heleneborgsgatan 54, 11732, stockholm</a>
        </div>
      </div>
    </footer>
  )
}
